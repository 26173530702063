/* ./src/index.css */
@tailwind base;
@tailwind components;

@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&display=swap');
@import url('http://fonts.googleapis.com/css?family=Droid+Sans:400,700');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wdth,wght@90,350&display=swap');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

@font-face {
    font-family: AnonymusPro;
    font-weight: 400;
    src: url('./fonts/AnonymousPro-Regular.ttf');
}

@font-face {
    font-family: AnonymusPro;
    font-weight: 700;
    src: url('./fonts/AnonymousPro-Bold.ttf');
}

@font-face {
    font-family: JetBrainsMono;
    src: url('./fonts/JetBrainsMono-VariableFont_wght.ttf');
}



html {
    @apply bg-light;
}

.hyphens {
    hyphens: auto;
}

h2 {
    @apply text-blue text-xl font-anom;
}

.hover-img {
    @apply hover:scale-[102%] 
    transition-all duration-500 cursor-zoom-in;
}

.text-content {
    @apply tracking-tight;
}


@tailwind utilities;